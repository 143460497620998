import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { formatNumber } from 'src/utils/checkout.utils';
import { PORTRAIT_FLEX, PORTRAIT_LAUNCH } from 'src/constants/general.constants';
import { useStyles } from './OrderConfirm.styles';
import { OrderContext } from '../index';
import OrderSubmit from './OrderSubmit';
import OrderOverview from './OrderOverview';
import OrderDeliveryOptions from './OrderDeliveryOptions';
import CardOnFile from './CardOnFile';
import OrderConfirmPanel from './OrderConfirmPanel';

const OrderDelivery: React.FC = (): React.ReactElement => {
  const classes = useStyles();
  const { orderData } = useContext(OrderContext) || {};
  const isFlexOrLite = orderData?.roleName === PORTRAIT_FLEX || orderData?.roleName === PORTRAIT_LAUNCH;

  return (
    <>
      <Box className={classes.orderDeliveryParentGrid}>
        <Box>
          <OrderOverview />
          <OrderDeliveryOptions isLegacy={!isFlexOrLite} />
          {isFlexOrLite && <CardOnFile />}
        </Box>

        {isFlexOrLite ? (
          <OrderSubmit />
        ) : (
          <OrderConfirmPanel title="Summary">
            <Box className={classes.orderPayment}>
              <Box className={classes.orderPaymentRow}>
                <Typography>Subtotal</Typography>
                <Typography>{`$${formatNumber(orderData?.subtotal ?? 0)}`}</Typography>
              </Box>
            </Box>
          </OrderConfirmPanel>
        )}
      </Box>
      <Box className={classes.viewMore} />
    </>
  );
};

export { OrderDelivery, OrderDelivery as default };
