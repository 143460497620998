import React, { useState } from 'react';
import Page from 'src/components/common/Page';
import Breadcrumb from 'src/components/DashboardAdministrator/common/Breadcrumb';
import { EHRButton } from 'src/components/ui/v1';
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { IOrderProductAdmin } from 'src/services/Products';
import { ClassNameMap } from 'src/types/Dom';
import { useSuppliers } from 'src/hooks/queries/useSuppliers';
import CreateOrEditProduct from './CreateOrEditProduct';
import ProductsTable from './ProductsTable';
import ImportProducts from './ImportProducts';

export const OrderProducts: React.FC<ClassNameMap> = ({ classes }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const [focusProduct, setFocusProduct] = useState<IOrderProductAdmin | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [supplierId, setSupplierId] = useState<number | 'all'>('all');

  const { data: suppliers = [] } = useSuppliers();

  const editProduct = (product: IOrderProductAdmin) => {
    setFocusProduct(product);
    setDialogOpen(true);
  };

  const createProduct = () => {
    setFocusProduct(undefined);
    setDialogOpen(true);
  };

  return (
    <Page title="Products">
      <Container classes={classes}>
        <Breadcrumb prependHeading="Inventory" />
        <Box display="flex" justifyContent="space-between" alignItems="center" margin={0} paddingY="8px">
          <Box display="flex" alignItems="center" flex={1} marginRight={2}>
            <FormControl variant="outlined" className={classes.searchField}>
              <OutlinedInput
                placeholder="Search products..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                inputProps={{
                  'data-cy': 'products-search-input',
                  'aria-label': 'Search products',
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.filterField}>
              <Select
                inputProps={{
                  'data-cy': 'products-supplier-select',
                  'aria-label': 'Filter by supplier',
                }}
                value={supplierId}
                onChange={(e) => setSupplierId(e.target.value as number | 'all')}
                displayEmpty
              >
                <MenuItem value="all">All Suppliers</MenuItem>
                {suppliers.map((supplier) => (
                  <MenuItem key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex">
            <EHRButton
              text="Import Products"
              color="primary"
              dataCy="importProductsButton"
              className={classes?.button}
              onClick={() => setImportDialogOpen(true)}
            />
            <EHRButton
              text="New Product"
              color="primary"
              dataCy="newProductButton"
              className={classes?.button}
              onClick={createProduct}
            />
          </Box>
        </Box>
        <ProductsTable editProduct={editProduct} searchQuery={searchQuery} supplierId={supplierId} />
        <CreateOrEditProduct open={dialogOpen} dismiss={() => setDialogOpen(false)} row={focusProduct} />
        <ImportProducts open={importDialogOpen} dismiss={() => setImportDialogOpen(false)} />
      </Container>
    </Page>
  );
};

export default withStyles({
  maxWidthLg: {
    maxWidth: 'unset',
  },
  button: {
    margin: 'auto',
    marginRight: '0',
    width: 135,
    '&:last-child': {
      marginLeft: 10,
    },
  },
  searchField: {
    paddingLeft: 20,
    width: 300,
    marginRight: 16,
  },
  filterField: {
    width: 200,
  },
})(OrderProducts);
