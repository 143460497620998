import moment from 'moment';
import { INPUT_DATE_FORMAT, PACIFIC_TZ } from 'src/constants/inventory.constants';
import { IDeliveryBlackoutDates } from 'src/interfaces/IInventoryOrder';

type Props = {
  date: moment.Moment;
  blackoutDates?: string[];
  isLegacy?: boolean;
};

const isOutsideLegacyRange = (date: moment.Moment, today: moment.Moment): boolean => {
  const isBeforeFollowingTuesday = date < today.startOf('isoWeek').add(8, 'days');
  const isAfterFollowingFriday = date > today.startOf('isoWeek').add(11, 'days');

  return isBeforeFollowingTuesday || isAfterFollowingFriday;
};

const isFartherThanAWeek = (date: moment.Moment, now: moment.Moment): boolean => date >= now.clone().add(8, 'days');

const isWeekendOrMonday = (date: moment.Moment): boolean => [0, 1, 6].indexOf(date.weekday()) > -1;

const isBadNextDay = (date: moment.Moment, now: moment.Moment) =>
  date.isSame(now.clone().add(1, 'day'), 'day') && now.hours() >= 12;

const isSameDay = (date: moment.Moment, now: moment.Moment): boolean => date.isSame(now, 'day');

export const invalidDeliveryDate = ({ date, blackoutDates = [], isLegacy = false }: Props) => {
  const now = moment().tz(PACIFIC_TZ);

  return (
    !date ||
    isWeekendOrMonday(date) ||
    isSameDay(date, now) ||
    blackoutDates.indexOf(date.clone().utc().format(INPUT_DATE_FORMAT)) > -1 ||
    (isLegacy && isOutsideLegacyRange(date, now)) ||
    isFartherThanAWeek(date, now) ||
    isBadNextDay(date, now)
  );
};

export const formatBlackoutDates = (blackoutDates: IDeliveryBlackoutDates[]): string[] =>
  blackoutDates.map(({ blackoutDate }: IDeliveryBlackoutDates) => moment.utc(blackoutDate).format(INPUT_DATE_FORMAT)) ??
  [];
