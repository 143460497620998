import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, makeStyles, Tooltip } from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';
import { Info as InfoIcon } from '@material-ui/icons';
import EHRTimePickerSelector from './EHRTimePickerSelector';
import { EHRDatePicker } from './EHRDatePicker';
import { EHRTypography } from './EHRTypography';

export type MissingInput = 'start' | 'end' | null | '';

export const validateInputConfirmation = (
  missingInput: MissingInput,
  setError: (key: any, params: any) => void,
  clearErrors: (param: any) => void,
  startKey: string = 'start',
  endKey: string = 'end'
) => {
  if (missingInput) {
    const inputKey = missingInput === 'start' ? startKey : endKey;
    // Set error to be shown after submit button:
    setError(inputKey, { type: 'custom', message: 'Please confirm' });
    return false;
  }
  clearErrors([startKey, endKey]);

  return true;
};

const useStyles = makeStyles(() => ({
  ehrDatetimeStartEndRangeContainer: {},
  ehrDate: { minWidth: '115px', alignItems: 'center', height: '40px' },
  ehrTimePickerSelected: {
    background: '#b8b8b8',
    color: 'black',
    height: '2.5rem',
    border: 'solid 1.5px #8c8c8c',
    borderRadius: '5px',
  },
}));

export interface IDatetimeRangeSelector {
  start: Date;
  end: Date;
}

export interface IDatetimeRangeSelectorUndefined {
  start: Date;
  end: null;
}

export interface EHRDatetimeStartEndRangeSelectorProps {
  dataCy: string;
  dateRange?: IDatetimeRangeSelector | IDatetimeRangeSelectorUndefined;
  defaultDateRange?: IDatetimeRangeSelector | IDatetimeRangeSelectorUndefined;
  onChange?: (newDateTimeRange: IDatetimeRangeSelector | IDatetimeRangeSelectorUndefined) => void;
  label?: string;
  id?: string;
  error?: boolean;
  tooltip?: string;
  disabled?: boolean;
  dateSelectorDisabled?: boolean;
  dateSelectorError?: boolean;
  dateSelectorHelperText?: string;
  dateSelectorRef?: any;
  dateSelectorName?: string;
  startTimeSelectorDisabled?: boolean;
  startTimeSelectorError?: boolean;
  startTimeSelectorHelperText?: string;
  startTimeSelectorRef?: any;
  startTimeSelectorName?: string;
  endTimeSelectorDisabled?: boolean;
  endTimeSelectorError?: boolean;
  endTimeSelectorHelperText?: string;
  endTimeSelectorRef?: any;
  endTimeSelectorName?: string;
  dateSelectorReadOnly?: boolean;
  handleMissingConfirmation: (missing: MissingInput) => void;
}

const extractFormattedAndTimeString = (date: Date | null | undefined) => {
  const momentDate = moment(date);
  if (date === null || date === undefined || date.toString() === 'Invalid Date') {
    return ['', ''];
  }
  const dateDateString = momentDate.format('YYYY-MM-DD');
  const dateTimeStringFormatted = momentDate.format('HH:mm');
  return [dateDateString, dateTimeStringFormatted];
};

const EHRDatetimeStartEndRangeSelector = (props: EHRDatetimeStartEndRangeSelectorProps) => {
  const classes = useStyles();

  const now = new Date();
  if (now.getMinutes() <= 30) {
    now.setMinutes(30);
  } else {
    now.setHours(now.getHours() + 1);
    now.setMinutes(0);
  }
  const later = new Date(now);
  later.setHours(now.getHours() + 1);

  const {
    dataCy,
    dateRange,
    onChange,
    label,
    id,
    error,
    tooltip,
    defaultDateRange,
    disabled,
    dateSelectorDisabled = false,
    dateSelectorError = false,
    dateSelectorHelperText,
    dateSelectorRef,
    dateSelectorName,
    startTimeSelectorDisabled = false,
    startTimeSelectorError = false,
    startTimeSelectorHelperText,
    startTimeSelectorRef,
    startTimeSelectorName,
    endTimeSelectorDisabled = false,
    endTimeSelectorError = false,
    endTimeSelectorHelperText,
    endTimeSelectorRef,
    endTimeSelectorName,
    dateSelectorReadOnly,
    handleMissingConfirmation,
  } = props;
  const [currentDateRange, setCurrentDateRange] = useState<IDatetimeRangeSelector | IDatetimeRangeSelectorUndefined>(
    dateRange || defaultDateRange || { start: now, end: later }
  );
  const [editInput, setEditInput] = useState('');

  const [lastSetDate, setLastSetDate] = useState<string>('');

  const onDateChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    const newDateString = e.target.value;
    setLastSetDate(newDateString);
    const [_, startTimeStringFormatted] = extractFormattedAndTimeString(currentDateRange.start);
    const newStartDateTime = moment(new Date(`${newDateString} ${startTimeStringFormatted}`));
    if (!newStartDateTime.isValid()) {
      return;
    }

    const newCurrentDateRange = {
      ...currentDateRange,
      start: newStartDateTime.toDate(),
    };

    if (!dateRange) {
      setCurrentDateRange(newCurrentDateRange);
    } else {
      onChange?.(newCurrentDateRange);
    }
  };

  const onStartTimeChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    const newStartTimeString = e.target.value;

    const dateToUse = lastSetDate || extractFormattedAndTimeString(currentDateRange.start)[0];
    const newStartDateTime = moment(new Date(`${dateToUse} ${newStartTimeString}`));
    if (!newStartDateTime.isValid()) {
      return;
    }

    const newCurrentDateRange = { ...currentDateRange };
    if (newStartDateTime.isAfter(newCurrentDateRange.end)) {
      newCurrentDateRange.end = newStartDateTime.toDate();
      newCurrentDateRange.end.setMinutes(newCurrentDateRange.end.getMinutes() + 30);
    }
    newCurrentDateRange.start = newStartDateTime.toDate();
    if (!dateRange) {
      setCurrentDateRange(newCurrentDateRange);
    } else {
      onChange?.(newCurrentDateRange);
    }
  };

  const onEndTimeChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    const newEndTimeString = e.target.value;
    const [todayDateString] = extractFormattedAndTimeString(currentDateRange.start);
    const newEndDateTime = moment(new Date(`${todayDateString} ${newEndTimeString}`));
    if (!newEndDateTime.isValid()) {
      return;
    }

    const newCurrentDateRange = { ...currentDateRange };
    if (newEndDateTime.isBefore(currentDateRange.start)) {
      newCurrentDateRange.start = newEndDateTime.toDate();
      newCurrentDateRange.start.setMinutes(newCurrentDateRange.start.getMinutes() - 30);
    }
    newCurrentDateRange.end = newEndDateTime.toDate();
    if (!dateRange) {
      setCurrentDateRange(newCurrentDateRange);
    } else {
      onChange?.(newCurrentDateRange);
    }
  };

  useEffect(() => {
    onChange?.(currentDateRange);
  }, [currentDateRange]);

  useEffect(() => {
    handleMissingConfirmation?.(editInput);
  }, [editInput]);

  const [startDateStringFormatted, startTimeStringFormatted] = extractFormattedAndTimeString(
    dateRange?.start || currentDateRange.start
  );
  const [_endDateStringFormatted, endTimeStringFormatted] = extractFormattedAndTimeString(
    dateRange?.end || currentDateRange.end
  );

  return (
    <Box display="flex" flexDirection="column" gridGap="0.5rem">
      {label && (
        <EHRTypography dataCy={`label-for-${id}`} id={id} variant="label" error={error}>
          <Box display="flex" gridGap="0.25rem" alignItems="center">
            {label}
            {tooltip && (
              <Tooltip title={tooltip} style={{ fontSize: '1rem' }}>
                <InfoIcon />
              </Tooltip>
            )}
          </Box>
        </EHRTypography>
      )}
      <Box
        display="flex"
        flexDirection="row"
        gridGap="1rem"
        alignItems="center"
        className={classNames(classes.ehrDatetimeStartEndRangeContainer)}
      >
        <Box flex={1} className={classes.ehrDate}>
          <EHRDatePicker
            value={startDateStringFormatted}
            readOnly={dateSelectorReadOnly}
            valueFormat="LL"
            dataCy={`${dataCy}-date`}
            onChange={onDateChange}
            disabled={disabled || dateSelectorDisabled}
            error={dateSelectorError}
            helperText={dateSelectorHelperText}
            ref={dateSelectorRef}
            name={dateSelectorName}
          />
        </Box>
        <Box flex={0.25}>
          <EHRTimePickerSelector
            dataCy={`${dataCy}-startTime`}
            value={startTimeStringFormatted}
            onChange={onStartTimeChange}
            disabled={disabled || startTimeSelectorDisabled}
            error={startTimeSelectorError}
            helperText={startTimeSelectorHelperText}
            ref={startTimeSelectorRef}
            name={startTimeSelectorName}
            onEditInput={(value: boolean) => setEditInput(value ? 'start' : '')}
            edit={editInput === 'start'}
          />
        </Box>
        <Box flex={0.25}>
          <EHRTimePickerSelector
            dataCy={`${dataCy}-endTime`}
            value={endTimeStringFormatted}
            onChange={onEndTimeChange}
            disabled={disabled || endTimeSelectorDisabled}
            error={endTimeSelectorError}
            helperText={endTimeSelectorHelperText}
            ref={endTimeSelectorRef}
            name={endTimeSelectorName}
            onEditInput={(value: boolean) => setEditInput(value ? 'end' : '')}
            edit={editInput === 'end'}
          />
        </Box>
      </Box>
      {!!startTimeSelectorHelperText && (
        <FormHelperText error={startTimeSelectorError}>{startTimeSelectorHelperText}</FormHelperText>
      )}
      {!!endTimeSelectorHelperText && (
        <FormHelperText error={endTimeSelectorError}>{endTimeSelectorHelperText}</FormHelperText>
      )}
    </Box>
  );
};

export { EHRDatetimeStartEndRangeSelector };
