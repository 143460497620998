import React from 'react';
import {
  SupervisorAccount as SupervisorAccountIcon,
  PeopleAlt as PeopleAltIcon,
  ListAlt as ListAltIcon,
  ShowChart as ShowChartIcon,
  AttachMoney as MoneyIcon,
  BarChart as BarChartIcon,
  DateRange as DateRangeIcon,
  BubbleChart as BubbleChartIcon,
} from '@material-ui/icons';
import { ReactComponent as HeartHandIcon } from 'src/assets/images/heart-hand.svg';
import classNames from 'classnames';
import { ClassNameMap } from 'src/types/Dom';
import { MEDSPA_ADMIN_RESOURCES, MEDSPA_ADMIN_URLS } from 'src/routes/medspaAdminRoutes';
import { ONBOARDING_BASE_PATH } from 'src/routes/medspaOnboardingRoutes';
import { CUSTOM_SERVICES, RESOURCE_READ } from 'src/constants/actions.constants';

type Props = {
  onboardingEnabled: boolean;
  hasOptedInMarketing: boolean;
  hasOptedInToDoList: boolean;
  isMedspaLegacyOrLegacyPlus: boolean;
} & ClassNameMap;

export const getMedspaOptions = ({
  onboardingEnabled,
  hasOptedInMarketing,
  hasOptedInToDoList,
  isMedspaLegacyOrLegacyPlus,
  classes,
}: Props) => {
  const className = (isActive: boolean) =>
    classNames(classes?.iconColor, classes ? { [classes?.iconActive]: isActive } : {});
  return [
    {
      name: 'My Portrait',
      permission: '',
      options: [
        ...(onboardingEnabled ? [{ name: 'To Do', url: ONBOARDING_BASE_PATH, permission: '' }] : []),
        { name: 'Resources', url: MEDSPA_ADMIN_RESOURCES, permission: RESOURCE_READ },
      ],
      icon: (isActive: boolean) => <BubbleChartIcon className={className(isActive)} />,
    },
    {
      name: 'Earnings',
      url: MEDSPA_ADMIN_URLS.Earnings,
      permission: '',
      icon: (isActive: boolean) => <MoneyIcon className={className(isActive)} />,
    },
    {
      name: 'Patients',
      url: MEDSPA_ADMIN_URLS.Patients,
      permission: '',
      icon: (isActive: boolean) => <SupervisorAccountIcon className={className(isActive)} />,
    },
    {
      name: 'Services',
      url: MEDSPA_ADMIN_URLS.ServicesManagement,
      permission: CUSTOM_SERVICES,
      icon: (isActive: boolean) => <HeartHandIcon className={className(isActive)} />,
    },
    {
      name: 'Inventory Ordering',
      url: MEDSPA_ADMIN_URLS.Inventory,
      permission: '',
      icon: (isActive: boolean) => <ShowChartIcon className={className(isActive)} />,
    },
    ...(hasOptedInMarketing
      ? [
        {
          name: 'Marketing',
          url: MEDSPA_ADMIN_URLS.Marketing,
          permission: '',
          icon: (isActive: boolean) => <BarChartIcon className={className(isActive)} />,
        },
      ]
      : []),
    ...(isMedspaLegacyOrLegacyPlus || hasOptedInToDoList
      ? [
        {
          name: 'To-do list',
          url: MEDSPA_ADMIN_URLS.TodoList,
          permission: '',
          icon: (isActive: boolean) => <ListAltIcon className={className(isActive)} />,
        },
      ]
      : []),
    ...(!isMedspaLegacyOrLegacyPlus
      ? [
        {
          name: 'Providers',
          url: MEDSPA_ADMIN_URLS.Practitioners,
          permission: '',
          icon: (isActive: boolean) => <PeopleAltIcon className={className(isActive)} />,
        },
      ]
      : []),
    ...(!isMedspaLegacyOrLegacyPlus
      ? [
        {
          name: 'Scheduling',
          permission: '',
          options: [
            { name: 'Calendar', permission: '', url: MEDSPA_ADMIN_URLS.Calendar },
            { name: 'Provider Configuration', permission: '', url: MEDSPA_ADMIN_URLS.CalendarConfiguration },
            { name: 'Appointment Types', permission: '', url: MEDSPA_ADMIN_URLS.AppointmentTypesConfig },
            { name: 'Locations', permission: '', url: MEDSPA_ADMIN_URLS.Locations },
          ],
          icon: (isActive: boolean) => <DateRangeIcon className={className(isActive)} />,
        },
      ]
      : [
        {
          name: 'Scheduling',
          permission: '',
          options: [
            { name: 'Calendar', permission: '', url: MEDSPA_ADMIN_URLS.Calendar },
            { name: 'Provider Configuration', permission: '', url: MEDSPA_ADMIN_URLS.CalendarConfiguration },
            { name: 'Locations', permission: '', url: MEDSPA_ADMIN_URLS.Locations },
          ],
          icon: (isActive: boolean) => <DateRangeIcon className={className(isActive)} />,
        },
      ]),
  ];
};
