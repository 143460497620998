import { GroupingPanel } from '@devexpress/dx-react-scheduler-material-ui';
import React, { useState } from 'react';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';
import { GroupingPanelCell } from './GroupingPanelCell';
import OverrideHoursModal from './OverrideHoursModal';

interface MedspaCalendarGroupingPanelProps {
  providersMap: { [key: number]: IPractitioner };
  selectedMedspaLocation: IMedspaLocation;
  date: Date;
  workingHoursMap: { [key: number]: IWorkingHour[] };
}

const MedspaCalendarGroupingPanel = (props: MedspaCalendarGroupingPanelProps) => {
  const { providersMap, selectedMedspaLocation, date, workingHoursMap } = props;
  const [overrideHoursSelectedProvider, setOverrideHoursSelectedProvider] = useState<IPractitioner | null>(null);
  const [existingOverrideHour, setExistingOverrideHour] = useState<IWorkingHour | null>(null);
  const [selectedWorkingHours, setSelectedWorkingHours] = useState<IWorkingHour[]>([]);

  const closeOverrideHoursModal = () => setOverrideHoursSelectedProvider(null);

  return (
    <>
      <GroupingPanel
        cellComponent={(cellComponentProps) => {
          const provider = providersMap[cellComponentProps.group.id as number];
          const workingHours = workingHoursMap[provider.id];
          return (
            <GroupingPanelCell
              {...cellComponentProps}
              provider={provider}
              onOverrideHoursClick={() => {
                const retrievedExistingOverrideHour = workingHours?.filter(
                  (workingHour) => workingHour.type === 'override_working_hours'
                )[0];
                setOverrideHoursSelectedProvider(provider);
                setExistingOverrideHour(retrievedExistingOverrideHour);
                setSelectedWorkingHours(workingHours);
              }}
            />
          );
        }}
      />
      {overrideHoursSelectedProvider && (
        <OverrideHoursModal
          date={date}
          open={!!overrideHoursSelectedProvider}
          onClose={closeOverrideHoursModal}
          provider={overrideHoursSelectedProvider}
          selectedMedspaLocation={selectedMedspaLocation}
          existingOverrideHour={existingOverrideHour}
          workingHours={selectedWorkingHours}
        />
      )}
    </>
  );
};

export default MedspaCalendarGroupingPanel;
