import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { dispatch } from 'src/rematch';
import { EHRButton } from 'src/components/ui/v1';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { useMedspaLocations } from 'src/hooks/queries/medspaAdmins/useMedspaLocations';
import { SIGN_UP_ROUTES } from '../../../constants/routes.constants';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    width: '100%',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface PageProps {
  title?: string;
  titleComponent?: React.ReactElement;
  children?: React.ReactNode;
}

const Page = ({ title, titleComponent, children }: PageProps) => {
  const classes = useStyles();
  const location = useLocation();
  const {
    data: { userGroup },
  } = useMedspaLocations();

  const isMedspaAdminRoute = location.pathname.startsWith('/medspa-admin/');

  const header = titleComponent ?? (
    <Box className={classes.titleContainer}>
      {title}
      {!!userGroup?.active && isMedspaAdminRoute && (
        <EHRButton
          dataCy="btn-registration-link"
          color="secondary"
          text="Booking Link"
          onClick={() => copyToClipboard(`${SIGN_UP_ROUTES.userGroupBooking}/${userGroup.slug}`)}
        />
      )}
    </Box>
  );

  useEffect(() => {
    dispatch.page.setTitleComponent(header);

    return () => {
      dispatch.page.setTitle(null);
    };
  }, [isMedspaAdminRoute, userGroup, title, titleComponent]);

  return <Box className={classes.pageContent}>{children}</Box>;
};

export default Page;
