export const ADMINS_LIST = 'adminUser';
export const ADMIN_USER_INFO = 'adminUserInfo';
export const AD_CAMPAIGNS = 'adCampaigns';
export const APPOINTMENT_REMINDER_CONFIGURATION = 'appointmentReminderConfiguration';
export const ASSIGNABLE_PRACTITIONERS = 'assignablePractitioners';
export const CHECKOUT = 'checkout';
export const CLEARANCE_HISTORY = 'clearanceHistory';
export const CLEARANCE_HISTORY_STATISTIC = 'clearanceHistoryStatistic';
export const CLIENT_LEAD = 'clientLead';
export const CLIENT_LEAD_PROVIDERS = 'clientLeadProviders';
export const CLIENT_LEAD_REPORTS = 'clientLeadReports';
export const CLIENT_LEAD_CONTACT_DETAILS = 'clientLeadContactDetails';
export const CLIENT_LEAD_IDEMPOTENCY_KEY = 'clientLeadIdempotencyKey';
export const CONTACT_INFO = 'contactInfo';
export const CUSTOM_CARE_MESSAGE = 'customCareMessages';
export const CUSTOMER = 'customer';
export const CUSTOMER_SERVICE_REQUESTS = 'customerServiceRequestes';
export const CUSTOMER_VIEW = 'customerView';
export const CUSTOMER_NOTES = 'customerNotes';
export const CUSTOMER_PRACTITIONER_ASSIGNED = 'customerPractitionerAssigned';
export const CUSTOMER_SELECTOR = 'customerSelector';
export const CUSTOMERS_LIST = 'customersList';
export const CUSTOMER_PHOTOS = 'customerPhotos';
export const COUNT_CUSTOMER_LIST = 'countCustomerList';
export const DEFAULT_DIAGNOSES = 'defaultDiagnoses';
export const EDGE_TIERS = 'edgeTiers';
export const GRAPHICS_DASHBOARD = 'graphicsDashboard';
export const PHYSICIAN_APPROVAL_DRAFT = 'physicianApprovalDraft';
export const GLOBAL_NOTIFICATIONS = 'globalNotifications';
export const INCOMING_MEDICAL_HISTORY_ACK = 'incomingMedicalHistoryAcknowledgement';
export const INVENTORY_BUDGET = 'inventoryBudget';
export const INVENTORY_NOTIFICATIONS = 'inventoryNotifications';
export const INVENTORY_ORDER = 'inventoryOrder';
export const INVENTORY_ORDER_REVIEW = 'inventoryOrderReview';
export const INVENTORY_ORDER_STATUS = 'inventoryOrderStatus';
export const INVENTORY_ORDER_PERIOD = 'inventoryOrderPeriod';
export const ORDER_PRODUCTS = 'orderProducts';
export const INVENTORY_ORDER_BLACKOUT_DATES = 'inventoryOrderBlackoutDates';
export const INVENTORY_ORDERS = 'inventoryOrders';
export const INVENTORY_ORDERS_ADMIN = 'inventoryOrdersAdmin';
export const LOCATIONS = 'locations';
export const LOCATION_INFO = 'locationInfo';
export const MARKETING_CHANNELS = 'marketingChannels';
export const MEDICAL_HISTORY_ACK = 'medicalHistoryAcknowledgement';
export const MEDSPA_PATIENTS_VIEW = 'medspaPatients';
export const MEDSPA_PROVIDER_PATIENTS_VIEW = 'medspaProviderPatients';
export const MEDSPA_PATIENTS_VIEW_COUNT = 'medspaPatientsCount';
export const MEDSPA_ADMINS = 'medspaAdmins';
export const MEDSPA_ADMIN_INFO = 'medspa_admin_info';
export const MEDSPA_PRACTITIONERS = 'medspaPractitioners';
export const MEDSPA_PRACTITIONERS_YEAR_STATS = 'medspaPractitionersYearStatistics';
export const MEDSPA_EARNINGS_CALCULATIONS = 'medspaEarningsCalculations';
export const MEDSPA_SUMMARIZE_TRANSACTIONS = 'medspaSummarizeTransactions';
export const MEDSPA_ROLE_NAME = 'medspaRoleName';
export const MEDSPA_FLEX_TRANSACTIONS = 'medspaFlexTransactions';
export const MEDSPA_OPEN_SERVICE_VISITS = 'medspaOpenServiceVisits';
export const MEDSPA_CHART_REVIEWS = 'medspaChartReviews';
export const MEDSPA_CONSENTS = 'medspaConsents';
export const MEDSPA_CARE_MESSAGES = 'medspaCareMessages';
export const MEDSPA_CUSTOM_CARE_MESSAGES = 'medspaCustomCareMessages';
export const MEDSPA_STANDING_ORDERS = 'medspaStadingOrders';
export const MEDSPA_DEFAULT_SERVICE_GROUPS = 'medspaServiceGroups';
export const MEDSPA_PRIMARY_SERVICES = 'medspaPrimaryServices';
export const MONTHLY_BLOCKERS = 'monthlyBlockers';
export const NOTIFICATIONS = 'notifications';
export const PATIENTS = 'patient';
export const PATIENT_BASIC_INFO = 'patientBasicInfo';
export const PATIENT_CURRENT_BALANCE = 'patientCurrentBalance';
export const PATIENT_INFO = 'patientInfo';
export const PATIENT_MESSAGES = 'patientMessages';
export const PATIENT_PHOTOS = 'patientPhotos';
export const PATIENT_VIEWED = 'patientViewed';
export const PAYMENT_METHODS = 'paymentMethods';
export const PAYMENT_STATUS = 'paymentStatus';
export const PHYSICIANS = 'physicians';
export const PHYSICIAN_INFO = 'physicianInfo';
export const PHYSICIAN_ASSIGNED_PROVIDERS = 'physicianAssignedProviders';
export const PRACTITIONERS = 'practitioners';
export const PRACTITIONER_CHART_REVIEWS = 'practitionerChartReviews';
export const PRACTITIONER_INFO = 'practitionerInfo';
export const PRACTITIONER_REPORT_COLS = 'practitionerReportCols';
export const PRACTITIONER_REPORTS = 'practitionerReports';
export const PRACTITIONER_SMS_TEMPLATES = 'practitionerSMSTemplates';
export const PRACTITIONER_PRIMARY_SERVICES = 'practitionerPrimaryServices';
export const PRODUCT_UTILIZATION = 'productUtilization';
export const PURCHASE_ORDERS = 'purchaseOrders';
export const SERVICES = 'services';
export const SERVICE = 'service';
export const SPECIAL_OFFERS = 'specialOffers';
export const SPECIAL_OFFER = 'specialOffer';
export const DEFAULT_SERVICES = 'defaultServices';
export const SERVICES_LIST = 'servicesList';
export const SERVICES_LIST_COUNT = 'servicesListCount';
export const SERVICE_GROUPS_LIST = 'serviceGroupsList';
export const SERVICE_GROUPS = 'serviceGroups';
export const DEFAULT_SERVICE_GROUPS = 'defaultServiceGroups';
export const SERVICE_SELECTED_ITEMS = 'servicesSelectedItems';
export const SERVICE_PRICING = 'servicePricing';
export const SHIP_TO_NUMBERS = 'shipToNumbers';
export const SUPPLY_RECEIPTS = 'supplyReceipts';
export const SUPPLY_RECEIPT = 'supplyReceipt';
export const ACTIVITY_LOGS = 'activityLogs';
export const MEDICAL_PROFILE = 'medicalProfile';
export const TRANSACTIONS_DASHBOARD = 'transactionsDashboard';
export const POSSIBLE_TRANSACTIONS_DASHBOARD = 'possibleTransactionsDashboard';
export const USER_GROUPS = 'userGroups';
export const USER_GROUPS_INFINITY = 'userGroupsInfinity';
export const USER_GROUPS_INFINITY_COUNT = 'userGroupsInfinityCount';
export const CURRENT_USER_GROUP = 'currentUserGroup';
export const EARNINGS_DASHBOARD = 'earningsDashboard';
export const UPDATE_MEDICAL_PROFILE = 'updateMedicalProfile';
export const UPCOMING_PATIENTS = 'upcomingPatients';
export const UPCOMING_PATIENT_COUNT = 'upcomingPatientCount';
export const SERVICE_ACKNOWLEDGMENTS = 'serviceAcknowledgments';
export const PROGRESS_NOTES = 'progressNotes';
export const MOVE_TO_RECLEAR = 'moveToReclear';
export const NEXT_APPOINTMENT = 'nextAppointment';
export const SERVICES_COMMISSIONS = 'servicesCommissions';
export const ROLES = 'roles';
export const MEDICAL_MEMBERS = 'medicalMembers';
export const STATES = 'states';
export const CITIES = 'CITIES';
export const COORDINATES = 'COORDINATES';
export const INFO_FROM_COORDINATES = 'INFO_FROM_COORDINATES';
export const PRACTITIONER_CONSENTS = 'practitionerConsents';
export const PRACTITIONER_CONSENT = 'practitionerConsent';
export const PHOTO_CONSENT = 'photoConsent';
export const PRACTITIONER_STANDING_ORDERS = 'practitionerStandingOrders';
export const PRACTITIONER_STANDING_ORDER = 'practitionerStandingOrder';
export const RECONSTITUTION_EXPIRY = 'reconstitutionExpiry';
export const VALID_TRACKING_NUMBER = 'validTrackingNumber';

export const ALL_SERVICE_GROUPS = 'service-groups';
export const SUPPLIERS_LIST = 'suppliers';
export const FEATURES = 'features';
export const APPOINTMENT_BOOKING_SLOTS = 'bookingSlots';
export const APPOINTMENT_TYPES = 'appointmentTypes';
export const CALENDAR_IDS = 'calendarIds';
export const SERVICE_VISIT = 'serviceVisit';
export const SERVICE_VISIT_OPENED = 'serviceVisitOpened';
export const SERVICE_VISITS = 'service-visits';
export const SERVICE_VISITS_FULL_HISTORY = 'fullServiceVisits';
export const PHYSICIAN_SERVICE_VISITS_BY_PROVIDER = 'physicianServiceVisitsByProvider';
export const SERVICE_VISIT_SESSION_ID = 'service-visit-session-id';

export const CHECKOUT_TENDERS = 'checkoutTenders';
export const CHECKOUT_TRANSACTIONS = 'checkoutTransactions';
export const CHECKOUT_TRANSACTIONS_COUNT = 'checkoutTransactionsCount';
export const CHECKOUT_TRANSACTIONS_LINE_ITEMS = 'checkoutTransactionsLineItems';
export const CHECKOUT_TRANSACTIONS_LINE_ITEMS_COUNT = 'checkoutTransactionsLineItemsCount';
export const CHECKOUT_TRANSACTIONS_DISCOUNTS = 'checkoutTransactionsDiscounts';
export const CHECKOUT_TRANSACTIONS_DISCOUNT_COUNT = 'checkoutTransactionsDiscountCount';
export const CHECKOUT_TRANSACTIONS_TENDERS = 'checkoutTransactionsTenders';
export const CHECKOUT_TRANSACTIONS_TENDER_COUNT = 'checkoutTransactionsTenderCount';
export const CHECKOUT_TRANSACTIONS_ASSETS_SOLD = 'checkoutTransactionsAssetsSold';
export const CHECKOUT_TRANSACTIONS_ASSETS_SOLD_COUNT = 'checkoutTransactionsAssetsSoldCount';
export const CHECKOUT_TRANSACTIONS_DETAILS = 'checkoutTransactionsDetails';
export const PATIENTS_VIEW = 'patientsView';
export const PATIENTS_VIEW_COUNT = 'patientsViewCount';
export const VARIANT_ITEM_PRICING = 'variantItemPricing';
export const CUSTOMER_MEMBERSHIP_CONSENT = 'customerMembershipConsent';
export const MEMBERSHIP_CONSENT = 'membershipConsent';
export const SERVICE_VISIT_ASSET_COUNT = 'serviceVisitAssetPaginationCount';
export const SERVICE_VISIT_ASSETS = 'serviceVisitAssetsPagination';
export const SERVICE_VISIT_ASSET_SCANNED = 'serviceVisitAsset';
export const SERVICE_VISIT_CHECKOUT = 'serviceVisitCheckout';
export const SERVICE_VISIT_DISCOUNTS = 'serviceVisitDiscounts';
export const SERVICE_VISIT_CREDITS = 'serviceVisitCredits';
export const APPLIED_SERVICE_VISIT_CREDITS = 'appliedServiceVisitCredits';
export const APPLIED_SERVICE_VISIT_REFERRAL_CREDITS = 'appliedServiceVisitReferralCredits';
export const PATIENT_REFERRALS = 'patientReferrals';
export const SERVICE_VISIT_CERTIFICATES = 'serviceVisitCertificates';
export const SERVICE_VISIT_CERTIFICATES_USED = 'serviceVisitCertificatesUsed';
export const SERVICE_VISIT_PHOTOS = 'serviceVisitPhotos';
export const SERVICE_VISIT_STANDING_ORDERS = 'serviceVisitStandingOrders';
export const SERVICE_VISIT_CUSTOMER_STANDING_ORDERS = 'serviceVisitCustomerStandingOrders';
export const SERVICE_VISIT_CONSENTS = 'serviceVisitConsents';
export const SERVICE_VISIT_PHOTO_CONSENT = 'serviceVisitPhotoConsent';
export const SERVICE_VISIT_ANNOTATED_PHOTOS = 'serviceVisitAnnotatatedPhotos';
export const ACUITY_ACCOUNT = 'acuityAccount';
export const CARE_MESSAGES_SUGGESTIONS = 'careMessagesSuggestions';
export const ACUITY_CALENDAR_AVAILABILITY = 'acuityCalendarAvailability';
export const ACUITY_CALENDAR_AVAILABILITY_OVERRIDE = 'acuityCalendarAvailabilityOverride';
export const ACUITY_CALENDAR_BLOCK_OFF_HOURS = 'acuityCalendarBlockOffHours';
export const EXTERNAL_PHYSICIAN_NOTES = 'externalPhysicianNotes';
export const PROFILE_PROGRESS = 'profileProgress';
export const MEDSPA_ADMIN_ONBOARDING_PROGRESS = 'medspaAdminOnboardingProgress';
export const STANDING_ORDER_TEMPLATES = 'standingOrderTemplates';
export const CARE_MESSAGES = 'careMessages';
export const CARE_MESSAGE_TEMPLATES = 'careMessageTemplates';
export const CONSENTS = 'consents';
export const CONSENT_TEMPLATES = 'consentTemplates';
export const DOCUMENTS = 'documents';
export const MEDSPA_LOCATIONS = 'medspaLocation';
export const MEDSPA_LOCATION_PRACTITIONERS = 'medspaLocationPractitioner';
export const MEDSPA_LOCATION_SLUG = 'medspaLocationSlug';
export const MEDSPA_LOCATION_USER_GROUP = 'medspaLocationUserGroup';
export const MEDSPA_APPOINTMENT_TYPES = 'medspaAppointmentTypes';
export const MEDSPA_APPOINTMENT_HISTORY = 'medspaAppointmentHistory';

export const MEDSPA_ADMIN_ACCOUNT_SETTINGS = 'medspaAdminAccountSettings';
export const MEDSPA_ADMIN_BANK_ACCOUNT = 'medspaBankAccount';
export const MEDSPA_CALENDAR = 'medspaCalendar';
