/* eslint-disable max-len */
import { PHYSICIAN_ROLE, ADMIN_ROLE, PRACTITIONER_ROLE } from './general.constants';

const SIGN_IN = '/identities/sign-in';
export const REGISTRATION_SIGN_UP_PAGE = '/registration/sign-up';
export const REGISTRATION_PASSWORD_PAGE = '/registration/set-password';
export const REGISTRATION_LITE_MEDSPA_SIGN_UP_PAGE = '/registration/lite/medspa/sign-up';
export const REGISTRATION_LITE_MEDSPA_SIGN_UP_WAIT_PAGE = '/registration/lite/medspa/sign-up-wait';
const PRACTITIONER_SET_PASSWORD = '/v3/practitioners/set_password';
const BANDWIDTH_CONFIG = '/v3/settings/bandwidth_config';
const SEND_RESET_PASSWORD_EMAIL = '/identities/forgot-password';
const RESET_PASSWORD = '/identities/reset-password';
const EXTERNAL_LANDING_PAGE = '/external';
const CREATE_PRACTITIONER_REGISTRATION = '/v3/practitioner_registrations/signup';
const CREATE_MEDSPA_ADMIN_REGISTRATION = '/v4/medspa/registrations/medspa_signup';
export const CREATE_MEDSPA_ADMIN_INVITATION = '/v4/medspa/medspa_admin_invitations';
export const VALID_MEDSPA_ADMIN_INVITATION = '/v4/medspa/medspa_admin_invitations/valid';
const SET_PASSWORD_PRACTITIONER_REGISTRATION = '/v3/practitioner_registrations/set_password';
const TRACKING_NUMBERS_BASE = 'v4/inventory_orders/shipment_tracking_numbers';
const CONFIRMATION_NUMBERS_BASE = 'v4/inventory_orders/order_confirmation_numbers';
const INVENTORY_ORDER_CHARGES_BASE = 'v4/inventory_orders/inventory_order_charges';
const CLIENT_LEADS = (path?: number | string) => `client_leads${path ? `/${path.toString()}` : ''}`;
const AD_CAMPAIGNS = (id?: number) => `/v4/ad_campaigns${id ? `/${id.toString()}` : ''}`;
export const PUBLIC_CONTACT_DETAILS = CLIENT_LEADS('contact_details');
export const PUBLIC_LEAD_SUBMIT = CLIENT_LEADS('remote');
export const GET_RECONSTITUTION_EXPIRY = '/practitionersinfo/details/reconstitution_expiry_days';
export const MEDSPA_ADMIN_LIST = '/admins/group/medspa_admin';
export const USER_GROUP_ADMINS = (userGroupId: string | number) =>
  `${MEDSPA_V4_PATH}/${userGroupId}/user_group_admins/`;
export const USER_GROUP_BANK_ACCOUNTS = (userGroupId: string | number) =>
  `${MEDSPA_V4_PATH}/${userGroupId}/bank_accounts`;
export const USER_GROUP_ADMINS_UPDATE = (userGroupId: string, userId: string) =>
  `${MEDSPA_V4_PATH}/${userGroupId}/user_group_admins/${userId}`;
export const ASSIGNABLE_PRACTITIONERS_LIST = '/v3/practitioners/group/assignable_practitioners';
export const MEDSPA_PRACTITIONERS_LIST = '/admins/group/medspa_admin_practitioner';
export const GET_IDEMPOTENCY_KEY = '/analytics_events/validate/event_id';
export const CONSENTS = '/v3/consents';
export const PHOTO_CONSENT = '/v3/consents/photo_consent';
export const CONSENT_SUGGESTIONS = '/v3/service_groups/consent_suggestions';
export const STANDING_ORDERS = '/v3/standing_orders';
export const STANDING_ORDER_SUGGESTIONS = '/v3/service_groups/standing_order_suggestions';

export const MEDSPA_BASE_PATH = `/user_groups`;
export const MEDSPA_YEAR_STATISTICS_PATH = (userGroupId: string) =>
  `${MEDSPA_BASE_PATH}/${userGroupId}/year_statistics`;
export const MEDSPA_EARNINGS_CALCULATIONS_PATH = (userGroupId: string) =>
  `${MEDSPA_BASE_PATH}/${userGroupId}/earnings_calculations`;
export const MEDSPA_SUMMARIZE_TRANSACTIONS_PATH = (userGroupId: string) =>
  `${MEDSPA_BASE_PATH}/${userGroupId}/summarize_transactions`;
export const MEDSPA_FLEX_EARNINGS = (userGroupId: string) => `${MEDSPA_BASE_PATH}/${userGroupId}/flex_earnings`;
export const MEDSPA_CARD_LIST = (userGroupId: string) => `${USER_GROUP_ADMINS(userGroupId)}/list_customer_cards`;
export const MEDSPA_CARD_CREATE = (userGroupId: string) => `${USER_GROUP_ADMINS(userGroupId)}/add_credit_card`;
export const MEDSPA_ROLE_NAME_PATH = `${MEDSPA_BASE_PATH}/role`;
export const MEDSPA_PRICING_UPDATE = `${MEDSPA_BASE_PATH}/update_user_group_pricing`;
export const MEDSPA_PRICING = `${MEDSPA_BASE_PATH}/user_group_pricing`;
export const MEDSPA_V4_PATH = 'v4/medspa';

export const STATES = '/v3/states';
export const EXTERNAL_MEDICAL_DIRECTOR_SIGN_AGREEMENT = `${MEDSPA_V4_PATH}/external_medical_director/sign_agreement`;

export default {
  SIGN_IN,
  ROLES: '/v3/roles',
  MASQUERADE_PRACTITIONER: (id: number) => `/practitioners/${id}/masquerade`,
  ME: '/identities/current',

  // Checkout transactions
  PURCHASE_ORDERS: '/purchase-orders',
  CHECKOUT_TRANSACTIONS: '/v3/transactions',
  CHECKOUT_TRANSACTIONS_COUNT: '/v3/transactions/count',
  CHECKOUT_TRANSACTIONS_LINE_ITEMS: '/v3/transactions/line_items',
  CHECKOUT_TRANSACTIONS_LINE_ITEMS_COUNT: '/v3/transactions/line_items_count',
  CHECKOUT_TRANSACTIONS_DISCOUNTS: '/v3/transactions/discounts',
  CHECKOUT_TRANSACTIONS_DISCOUNT_COUNT: '/v3/transactions/discounts_count',
  CHECKOUT_TRANSACTIONS_TENDERS: '/v3/transactions/tenders',
  CHECKOUT_TRANSACTIONS_TENDER_COUNT: '/v3/transactions/tenders_count',
  CHECKOUT_TRANSACTIONS_ASSETS_SOLD: '/v3/transactions/assets_sold',
  CHECKOUT_TRANSACTIONS_ASSETS_SOLD_COUNT: '/v3/transactions/assets_sold_count',
  CHECKOUT_TRANSACTIONS_DETAILS: '/v3/transactions/checkout_details',
  CLIENT_LEADS,
  CLIENT_LEAD_REPORTS: CLIENT_LEADS('client_lead_reports'),
  CLIENT_LEAD_PROVIDERS: CLIENT_LEADS('client_lead_providers'),
  PRODUCT_UTILIZATION: '/assets/product_utilization',
  CUSTOMER_NO_SHOW: (id: number) => `/v3/customers/${id}/no_show`,
  CUSTOMER_VIEW: '/v3/customer_views',
  CUSTOMER_VIEW_COUNT: '/v3/customer_views/count',
  CUSTOMER_VIEW_SHOW: (id: number) => `/v3/customer_views/${id}`,
  PRACTITIONERS_V4_PATH: '/v4/practitioners',
  CUSTOMER_SERVICE_REQUESTS: (id: number) => `/patient-profile/${id}/service-requests`,
  DASHBOARD_CUSTOMERS_BY_ROLE: {
    [PHYSICIAN_ROLE.toLowerCase()]: '/v3/customers/index_for_physician',
    [ADMIN_ROLE.toLowerCase()]: '/customers/for-administrator-ehr',
    [PRACTITIONER_ROLE.toLowerCase()]: '/customers/for-practitioner',
  },
  CUSTOMER_PRACTITIONERS_ASSIGNED: '/v3/customers/practitioner_assigned',
  CUSTOMER_FOR_SELECTOR: '/v3/customers/selector',
  CUSTOMER_GFE_STATUS_TO_CLEAR: (id: number) => `/v3/gfe_status_to_clear/${id}`,
  APPOINTMENT_INDEX: '/v4/appointments',
  APPOINTMENT_BOOK: '/v3/appointments/create_from_provider',
  APPOINTMENT_RESCHEDULE: (id: number) => `/v3/appointments/${id}/reschedule`,
  APPOINTMENT_TYPES: '/v3/appointments/appointment_types',
  APPOINTMENT_TYPES_FOR_PROVIDER: '/v4/appointments/appointment_types',
  APPOINTMENT_ACCOUNT: '/v3/appointments/account',
  APPOINTMENT_TYPE_CARE_MESSAGES_SUGGESTION: '/v3/appointments/appointment_type_care_message_suggestions',
  APPOINTMENT_CALENDAR_AVAILABILITY: '/v3/appointments/availability',
  APPOINTMENT_CALENDAR_AVAILABILITY_OVERRIDE: '/v3/appointments/availability_override',
  APPOINTMENT_CALENDAR_BLOCK_OFF_HOURS: '/v3/calendar/blocks',
  APPOINTMENT_BOOKING_SLOT: '/practitioners/booking-slots',
  PRACTITIONER_CALENDAR_IDS: '/v3/appointments/practitioner_calendars',
  PRACTITIONER_LOCATIONS: '/v4/appointments/locations',
  APPOINTMENT_CANCELLATION: '/v3/appointments/cancel_provider_appointment',
  APPOINTMENT_REMINDER_CONFIGURATION: (id: number) => `/v3/practitioners/${id}/appointment_reminder_configuration`,
  INVENTORY_BUDGET: '/assets/ordering_budget',
  INVENTORY_ORDER_PERIOD: '/inventory_orders/period',
  INVENTORY_ORDER_BANNER: '/inventory_orders/banner',
  INVENTORY_ORDER_HISTORY: '/v4/inventory_orders',
  INVENTORY_ORDER_CURRENT: '/inventory_orders/order',
  INVENTORY_ORDER_RESET: '/inventory_orders/order/reset',
  INVENTORY_ORDER_REVIEW: '/inventory_orders/review',
  INVENTORY_ORDER_REMOVE: '/inventory_orders/order/remove',
  INVENTORY_ORDER_ITEM_UPSERT: '/v4/inventory_orders/inventory_order_items/upsert',
  INVENTORY_ORDER_SPECIAL_ITEM_UPSERT: '/v4/inventory_orders/inventory_order_specials/upsert',
  INVENTORY_ORDER_ITEM_DESTROY: (id: number) => `v4/inventory_orders/inventory_order_items/${id}`,
  PURCHASE_ORDER_ITEM: (id: number) => `v4/inventory_orders/purchase_order_items/${id}`,
  CONFIRMATION_NUMBERS: (id?: number) => `${CONFIRMATION_NUMBERS_BASE}${id ? `/${id.toString()}` : ''}`,
  INVENTORY_ORDER_CHARGES: (id?: number) => `${INVENTORY_ORDER_CHARGES_BASE}${id ? `/${id.toString()}` : ''}`,
  TRACKING_NUMBERS: (id?: number) => `${TRACKING_NUMBERS_BASE}${id ? `/${id.toString()}` : ''}`,
  VALIDATE_TRACKING_NUMBER: `${TRACKING_NUMBERS_BASE}/validate_tracking_number`,
  SHIP_TO_NUMBERS: (id?: number) => `v4/inventory_orders/ship_to_numbers${id ? `/${id.toString()}` : ''}`,
  INVENTORY_ORDER_CUSTOM_ITEMS: (id?: number) =>
    `v4/inventory_orders/inventory_order_custom_items${id ? `/${id.toString()}` : ''}`,
  INVENTORY_ORDER_CONFIRM_DELIVERY: '/inventory_orders/confirm_delivery',
  INVENTORY_ORDER: (id?: number, action?: string) =>
    `v4/inventory_orders${id ? `/${id.toString()}` : ''}${!!id && !!action ? `/${action}` : ''}`,
  INVENTORY_ORDER_BLACKOUT_DATES: (id?: number) =>
    `v4/inventory_orders/inventory_order_blackout_dates${id ? `/${id.toString()}` : ''}`,
  INVENTORY_ORDER_PRODUCTS: (id?: number) => `v4/inventory_orders/order_products${id ? `/${id.toString()}` : ''}`,
  SERVICE_VISIT_CREATE_VERSION: '/v3/service_visits/create',
  SERVICE_VISIT_ASSETS: '/v3/service_visit_assets',
  SERVICE_VISIT_ASSET_COUNT: '/v3/service_visit_assets/count',
  SERVICE_VISIT_ASSETS_SCANNED: (id: number) => `/v3/service_visit_assets/${id}`,
  SERVICE_VISIT_ASSETS_SCANNED_DESTROY: (id: number) => `/v3/service_visit_assets/${id}/destroy`,
  SERVICE_VISIT_SELECTED_SERVICES: (id: number) => `/v3/service_visits/${id}/line_items`,
  SERVICE_VISIT_SELECTED_SERVICES_REMOVE: (id: number) => `/v3/service_visits/${id}/line_items`,
  SERVICE_VISIT_UPDATE_LINE_ITEM: (id: number, checkoutLineItemId: number) =>
    `/v3/service_visits/${id}/line_items/${checkoutLineItemId}`,
  SERVICE_VISIT_FULL_HISTORY: (id: number) => `/service-visits/${id}/full-service-history`,
  SERVICE_VISIT: (id: number) => `/service-visits/${id}/current-service-visit`,
  SERVICE_VISIT_CHECKOUT: (id: number) => `/v3/service_visits/${id}/checkout`,
  SERVICE_VISIT_CHECKOUT_TENDERS: (id: number) => `/v3/service_visits/${id}/checkout_tenders`,
  SERVICE_VISIT_DISCOUNTS: (id: number) => `/v3/service_visits/${id}/line_items/discounts`,
  SERVICE_VISIT_CREDITS: (id: number) => `/v3/service_visits/${id}/service_credits`,
  SERVICE_VISIT_REFERRAL_CREDITS: (id: number) => `/v3/service_visits/${id}/use_referred`,
  SERVICE_VISIT_ASPIRE_CERTIFICATES: (id: number) => `/v3/service_visits/${id}/galderma/configure_treatment`,
  SERVICE_VISIT_ASPIRE_CERTIFICATES_USED: (id: number) => `/v3/service_visits/${id}/galderma/certificates_used`,
  SERVICE_VISIT_ASPIRE_VALIDATE_CERTIFICATES: (id: number) =>
    `/v3/service_visits/${id}/galderma/validate_user_certificates`,
  SERVICE_VISIT_ASPIRE_SEND_CODE: '/galderma/send_sms_redeem_patient_points',
  SERVICE_VISIT_ASPIRE_REDEEM_CODE: '/galderma/redeem_patient_points_to_certificates',
  APPLIED_SERVICE_VISIT_CREDITS: (id: number) => `/v3/service_visits/${id}/service_credits_applied`,
  APPLIED_SERVICE_VISIT_REFERRAL_CREDITS: (id: number) => `/v3/service_visits/${id}/used_referred`,
  SERVICE_VISIT_PHOTOS: (id: number) => `/v3/service_visits/${id}/photos`,
  SERVICE_VISIT_CUSTOMER_STANDING_ORDER: (id: number) => `/v3/service_visits/${id}/customer_standing_orders`,
  SERVICE_VISIT_STANDING_ORDERS: (id: number) => `/v3/service_visits/${id}/standing_orders`,
  SERVICE_VISIT_CONSENTS: (id: number) => `/v3/service_visits/${id}/consents`,
  // Old endpoint:
  SIGN_SERVICE_VISIT_CONSENTS: (patientId: number, serviceGroupId: number) =>
    `/customers/${patientId}/add-customer-signature/${serviceGroupId}`,
  SERVICE_VISIT_POST_MOVEMENTS: (id: number) => `/v3/service_visits/${id}/post_checkout_movements`,
  SERVICE_VISIT_PAY_WITH_CARD_OR_CASH: (id: number) => `/v3/customers/${id}/charge_for_services_and_products`,
  SERVICE_VISIT_FEE_PAY_WITH_CARD_OR_CASH: (id: number) => `/v3/customers/${id}/pay_charge_for_fee`,
  SERVICE_VISIT_PAY_STAGING: (id: number) => `/service_visits/${id}/pay_staging`,
  SERVICE_VISIT_REFUND_STAGING: (id: number) => `/v3/service_visits/${id}/undo_post_checkout_movements`,
  SERVICE_VISIT_REFUND: (id: number) => `service_visits/${id}/refund`,
  SERVICE_VISIT_SUBMIT: (id: number) => `/v3/service_visits/${id}/submit`,
  SERVICE_VISIT_GFE_DIAGNOSIS_ACCEPTED: (id: number) => `/v3/service_visits/${id}/set_gfe_diagnosis_accepted`,
  SERVICE_VISIT_ADD_NOTE: (id: number) => `service-visits/${id}/notes`,
  SERVICE_VISIT_ADD_MEDICAL_DIRECTOR_NOTE: (id: number) => `/v3/service_visits/${id}/medical_director_note`,
  SERVICE_VISIT_VERSION: (id: number) => `/v3/service_visits/${id}`,
  USER_GROUPS: '/user_groups',
  USER_GROUPS_INFINITY: '/v4/administrators/user_groups',
  USER_GROUPS_INFINITY_COUNT: '/v4/administrators/user_groups/count',
  v4_USER_GROUPS: '/v4/administrators/user_groups',
  USER_GROUPO_OPTIONS: '/user_groups/options',
  CURRENT_USER_GROUP: '/user_groups/current',
  AD_CAMPAIGNS,
  UPDATE_USER_GROUP: (id: number) => `/user_groups/${id}`,
  ASSET_RECOVER: (id: number) => `/v3/assets/${id}/recover-asset`,
  ASSETS_BY_PO_ITEM: (purchaseOrderItemId: number) => `/v3/assets/purchase_order_item/${purchaseOrderItemId}`,
  PRACTITIONER_REGISTRATIONS: '/v3/practitioner_registrations',
  RESET_PASSWORD_PRACTITIONER_REGISTRATIONS: '/v3/practitioner_registrations/send_reset_password',
  PRACTITIONER_SET_PASSWORD,
  CREATE_PRACTITIONER_REGISTRATION,
  CREATE_MEDSPA_ADMIN_REGISTRATION,
  CREATE_PRACTITIONER_INVITATION: '/v3/practitioner_invitations',
  SET_PASSWORD_PRACTITIONER_REGISTRATION,
  LOGIN_REDIRECT_EXCEPTIONS: [
    SIGN_IN,
    PRACTITIONER_SET_PASSWORD,
    BANDWIDTH_CONFIG,
    SEND_RESET_PASSWORD_EMAIL,
    RESET_PASSWORD,
    EXTERNAL_LANDING_PAGE,
    PUBLIC_CONTACT_DETAILS,
    PUBLIC_LEAD_SUBMIT,
    REGISTRATION_SIGN_UP_PAGE,
    CREATE_PRACTITIONER_REGISTRATION,
    CREATE_MEDSPA_ADMIN_REGISTRATION,
    REGISTRATION_PASSWORD_PAGE,
    SET_PASSWORD_PRACTITIONER_REGISTRATION,
    GET_IDEMPOTENCY_KEY,
    VALID_MEDSPA_ADMIN_INVITATION,
    STATES,
    EXTERNAL_MEDICAL_DIRECTOR_SIGN_AGREEMENT,
  ],
  CUSTOMER_MEMBERSHIP_CONSENTS: '/v3/customer_membership_consents',
  MEMBERSHIP_CONSENTS: '/v3/membership_consents',
  CREATE_CUSTOMER_PHOTO_CONSENT: (patientId: string | number) =>
    `/customers/${patientId}/create-customer-photo-consent`,
  CREATE_CUSTOMER_PHOTO_CONSENT_V2: (patientId: number) => `/v3/customers/${patientId}/customer_photo_consent`,
  UPDATE_CUSTOMER_PHOTO_CONSENT_V2: (patientId: number, consentId: number) =>
    `/v3/customers/${patientId}/customer_photo_consent/${consentId}`,
  GET_CUSTOMER_PHOTO_CONSENT: (patientId: number) => `/v3/customers/${patientId}/customer_photo_consent`,
  SERVICE_VISIT_ANNOTATED_PHOTOS: (id: number) => `/v3/service_visits/${id}/annotated_photos`,
  BANDWIDTH_CONFIG,
  SAVE_NETWORK_DATA_URL: '/v3/network/save_network_data',
  CREATE_CUSTOMER_BEAUTI_BANK_SUBSCRIPTION: (patientId: string) =>
    `/v3/customers/${patientId}/create_beauti_bank_subscription`,
  UPDATE_CUSTOMER_BEAUTI_BANK_SUBSCRIPTION: (patientId: string) =>
    `/v3/customers/${patientId}/update_beauti_bank_subscription`,
  SERVICE_VISIT_TOGGLE_SAVE: '/clinical_oversight/toggle_save',
  SEND_RESET_PASSWORD_EMAIL,
  RESET_PASSWORD,
  PHYSICIAN_PROVIDER_CLOSED_SERVICE_VISITS: `/v3/physicians/service_visits/by_practitioner_id`,
  MEDSPA_LOCATIONS: '/v4/medspa_locations',
  MEDSPA_LOCATION_CHECK_SLUG: '/v4/medspa_locations/check_slug',
  MEDSPA_LOCATION_PRACTITIONERS: '/v4/medspa_location_practitioners/medspa_locations_for_practitioner',
  MEDSPA_LOCATION_PRACTITIONERS_UPDATE: (id: string | number) => `/v4/medspa_location_practitioners/${id}/update`,
  MEDSPA_LOCATION_PRACTITIONERS_UPDATE_WORKING_HOURS: '/v4/medspa_location_practitioners/update_working_hours',
  MEDSPA_LOCATION_PRACTITIONERS_APPOINTMENTS_VIEW: '/v4/medspa_location_practitioners/view',
  SWITCH_ACCOUNTS: '/v4/identities/switch_account',
  MEDSPA_CALENDAR_BLOCK_OFF: (userGroupId: number) => `/v4/medspa/${userGroupId}/block_offs`,
  MEDSPA_CALENDAR_BLOCK_OFF_DELETE: (userGroupId: number, blockOffHourId: number) =>
    `/v4/medspa/${userGroupId}/block_offs/${blockOffHourId}`,
  MEDSPA_CALENDAR_BLOCK_OFF_UPDATE: (userGroupId: number, blockOffHourId: number) =>
    `/v4/medspa/${userGroupId}/block_offs/${blockOffHourId}`,
  MEDSPA_CALENDAR_ADDONS: (userGroupId: number) => `/v4/medspa/${userGroupId}/addons`,
  MEDSPA_CALENDAR_OVERRIDE_HOURS: `/v4/medspa_location_practitioners/create_override_hours`,
  MEDSPA_CALENDAR_OVERRIDE_HOURS_DELETE: (overrideHoursId: number) =>
    `/v4/medspa_location_practitioners/destroy_override_hours/${overrideHoursId}`,
  MEDSPA_CALENDAR_APPOINTMENTS: `/v4/appointments`,
  MEDSPA_CALENDAR_APPOINTMENTS_RESCHEDULE: (appointmentId: string | number | undefined) =>
    `/v4/appointments/${appointmentId}/reschedule`,
  MEDSPA_CALENDAR_APPOINTMENTS_FULL_CONTEXT: (appointmentId: number) =>
    `/v4/appointments/${appointmentId}/full_context`,
  MEDSPA_CALENDAR_APPOINTMENTS_HISTORY: (appointmentId: number) => `/v4/appointments/${appointmentId}/history`,
  MEDSPA_CALENDAR_APPOINTMENTS_UPDATE: (appointmentId: number) => `/v4/appointments/${appointmentId}`,
  MEDSPA_CALENDAR_APPOINTMENTS_CANCEL: (appointmentId: number) => `/v4/appointments/${appointmentId}/cancel`,
  MEDSPA_CALENDAR_APPOINTMENTS_CONFIRM: (appointmentId: number) =>
    `/v4/appointments/${appointmentId}/manual_confirmation`,
  MEDSPA_APPOINTMENT_TYPES: (userGroupId: string | number) => `/v4/medspa/${userGroupId}/appointment_types`,
  MEDSPA_PAYMENT_METHOD_SET_DEFAULT_CC: (userGroupId: number) => `${USER_GROUP_ADMINS(userGroupId)}set_default_cc`,
  MEDSPA_PAYMENT_METHOD_DELETE_CC: (userGroupId: number) => `${USER_GROUP_ADMINS(userGroupId)}delete_credit_card`,
};
