import { Box, Button, Modal } from '@material-ui/core';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from 'src/utils/formatNumber';
import { Close as CloseIcon } from '@material-ui/icons';
import { ReactComponent as Savings } from 'src/assets/images/party-popper.svg';
import { OrderContext } from '../index';
import { useStyles } from '../OrderConfirm/OrderConfirm.styles';

export const SavingsPopup = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { orderData: { savingsTotal = 0 } = {} } = useContext(OrderContext) || {};
  const { firstName } = useSelector(({ auth }: any) => auth);
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.savingsPopupModal} hideBackdrop>
      <Box style={{ outline: 'none' }}>
        <Box display="flex" justifyContent="space-between">
          <Box style={{ border: '1px solid #D4D4D4', borderRadius: '4px', padding: '0.5rem', height: '38px' }}>
            <Savings style={{ width: '20px', height: '20px' }} />
          </Box>
          <Button onClick={onClose} type="button" data-cy="close-savings-popup">
            <CloseIcon />
          </Button>
        </Box>
        <p>
          <b>Congratulations, {firstName}!</b>
        </p>
        <p>
          Your saving <span className={classes.savingsText}>{formatCurrency(savingsTotal)}</span>. Keep it up!
        </p>
        <p>You can see how much you are saving under &apos;You save&apos; in the bottom right corner.</p>
      </Box>
    </Modal>
  );
};
