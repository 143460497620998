import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import { CLIENT_LEAD, CLIENT_LEAD_PROVIDERS, CLIENT_LEAD_REPORTS } from 'src/constants/reactQuery.keys';
import {
  IClientLeadApi,
  IClientLeadStatus,
  IClientLeadLookup,
  IClientLeadProviders,
  ILeadReportParams,
  ILeadReportData,
} from 'src/interfaces/IClientLead';
import ClientLeads from 'src/services/ClientLeads';
import { handleQueryError } from 'src/utils/inventoryOrdering';

const useClientLeads = ({ disabled, ...params }: IClientLeadLookup): UseQueryResult<IClientLeadApi | null> =>
  useQuery<IClientLeadApi | null>({
    queryKey: [CLIENT_LEAD, Object.values(params).join('-')],
    queryFn: (): Promise<IClientLeadApi | null> => ClientLeads.getClientLeads(params),
    enabled: !disabled,
    onError: handleQueryError('fetching client leads'),
  });

const useClientLeadProviders = (): UseQueryResult<IClientLeadProviders | null> =>
  useQuery<IClientLeadProviders | null>({
    queryKey: [CLIENT_LEAD_PROVIDERS],
    queryFn: (): Promise<IClientLeadProviders | null> => ClientLeads.getClientLeadProviders(),
    onError: handleQueryError('fetching client lead providers'),
  });

const useClientLeadReports = ({
  practitionerId,
  adCampaignId,
  dateIncrement,
  disabled,
}: ILeadReportParams): UseQueryResult<ILeadReportData | null> =>
  useQuery<ILeadReportData | null>({
    queryKey: [CLIENT_LEAD_REPORTS, practitionerId, adCampaignId, dateIncrement],
    queryFn: (): Promise<ILeadReportData | null> =>
      ClientLeads.getClientLeadReports({
        practitionerId,
        adCampaignId,
        dateIncrement,
      }),
    enabled: !disabled,
    onError: handleQueryError('fetching client lead reports'),
  });

const useUpdateClientLeadStatus = (id: number): UseMutationResult<void, unknown, IClientLeadStatus, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (status: IClientLeadStatus): Promise<void> => {
      await ClientLeads.updateClientLead({ id, status });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [CLIENT_LEAD] });
    },
    onError: handleQueryError('updating client lead status'),
  });
};

const useUpdateClientLeadNotes = (id: number): UseMutationResult<void, unknown, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (notes: string): Promise<void> => {
      await ClientLeads.updateClientLead({ id, notes });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [CLIENT_LEAD] });
    },
    onError: handleQueryError('updating client lead notes'),
  });
};

export {
  useUpdateClientLeadStatus,
  useClientLeadReports,
  useClientLeadProviders,
  useUpdateClientLeadNotes,
  useClientLeads,
  useClientLeads as default,
};
